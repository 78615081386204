import React, { useState, useRef } from "react";
import * as classes from "./style.module.scss";

import * as expandClasses from "../ExpandOnHover/style.module.scss";

import MardownSimple from "~/src/components/MardownSimple/index";
import ExpandOnHover from "~/src/components/ExpandOnHover/index";

import classNames from "classnames";
interface MainButtonProps {
  children: React.ReactNode;
}
import { useTranslation } from "react-i18next";
import { visitLexicalEnvironment } from "typescript";

const startSettings = {
  debug: {
    expanded: true,
    hover: true,
  },
  normal: {
    expanded: false,
    hover: false,
  },
};

const settings = startSettings.normal;

export const MainButton: React.FC<MainButtonProps> = ({ children }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(settings.expanded); // is the chat visible
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (contentRef.current && !contentRef.current.contains(e.target as Node)) {
      setExpanded(false);
    }
  };

  return (
    <>
      <div
        className={classNames(
          classes.background,
          expanded ? classes.backgroundVisible : "",
        )}
        onClick={handleBackgroundClick}
      ></div>
      <div className={classes.wrapper}>
        <button
          className={classNames([
            settings.expanded && "hover",
            classes.button,
            expandClasses.hover,
          ])}
          onClick={handleClick}
        >
          <div className={classes.buttonImage}></div>
          <div className={classes.buttonTextWrapper}>
            <ExpandOnHover>
              <div
                className={classNames(
                  classes.buttonText,
                  // expanded ? classes.buttonTextBig : "",
                )}
              >
                <MardownSimple markdown={t("mainButton.hello")} />
              </div>
            </ExpandOnHover>
          </div>
        </button>

        <div
          ref={contentRef}
          className={`${classes.content} ${expanded ? classes.visible : ""}`}
        >
          {children}
        </div>
      </div>
    </>
  );
};
