import * as classes from "./style.module.scss";

interface ExpandOnHoverProps {
  children: React.ReactNode;
}

const ExpandOnHover: React.FC<ExpandOnHoverProps> = ({ children }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.inner}>{children}</div>
    </div>
  );
};

export default ExpandOnHover;
