.wrapper {
  display: grid;
  grid-template-rows: 0fr;
  grid-template-columns: 0fr;
  animation-duration: var(--root--transition-duration);
  animation-timing-function: var(--root--transition-timing-function);
  animation-fill-mode: forwards;
}

.hover:global(.hover) .wrapper,  /* for debugging */
.hover:hover .wrapper {
  animation-name: expand;
}

@keyframes expand {
  0% {
    grid-template-rows: 0fr;
    grid-template-columns: 0fr;
  }
  50% {
    grid-template-rows: 0.7fr;
    grid-template-columns: 1fr;
  }
  100% {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
}

.inner {
  overflow: hidden;
}
