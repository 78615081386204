// @import url("//fonts.signalwerk.ch/css/latest/family=Work+Sans:ital,wght@0,100..900;1,100..900.css");

:root,
:host {
  --root--transition-duration: 350ms;
  --root--transition-timing-function: ease-in;
}


.root {
  // reset alls styles
  all: unset;
  // font-family: "Work Sans", sans-serif;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;

  * {
    transition-duration: var(--root--transition-duration);
    transition-timing-function: var(--root--transition-timing-function);
  }
}

* {
  margin: 0;
  padding: 0;
  font: inherit; /* Get rid of all font sizes and heights */
}

p {
  margin: 0;
}

// todo
// this needs to be scoped to the component
button {
  color: inherit;
  border: 0;
  box-shadow: none;
  background: transparent;
  text-align: inherit;
  text-shadow: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  // line-height: 1;

  &:focus {
    outline: 0 !important;
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
}

:global(.button) {
  border: solid 1px #007bff;
  padding: 0.5em 1em;
}

.overlay {
  // centered overlay for warning
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  z-index: 1000;
  color: white;
  text-align: center;
  width: 50%;
  max-width: 500px;
  font-size: 1.5rem;
  font-weight: bold;
}
