:root,
:host {
  --main-button--wrapper--margin: 0.5rem;
  --main-button--wrapper--box-shadow: none;
  --main-button--wrapper--padding: 0.5rem;
  --main-button--wrapper--background-color: var(
    --colors--primary-background-color
  );

  --main-button--background--background-color: rgba(0, 0, 0, 0.5);

  --main-button--image--aspect-ratio: 1/1;
  --main-button--image--background-image: none;
  --main-button--image--width: 3rem;

  --main-button--text--line-height: 1.2;
  --main-button--text--max-width: 10rem;
  --main-button--text--padding-left: 1rem;
}

.wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: var(--main-button--wrapper--margin);
  padding: var(--main-button--wrapper--padding);

  background-color: var(--main-button--wrapper--background-color);
  box-shadow: var(--main-button--wrapper--box-shadow);
}

.button {
  // display: flex;
  // align-items: center;
  // justify-content: center;

  display: flex;
  flex-wrap: nowrap;
}

.buttonImage {
  width: var(--main-button--image--width);
  aspect-ratio: var(--main-button--image--aspect-ratio);
  background-image: var(--main-button--image--background-image);
  background-size: contain;
  background-repeat: no-repeat;
}

.buttonTextWrapper {
  transition-property: opacity, transform;
  transition-delay: calc(var(--root--transition-duration) * 0.5);

  opacity: 0;
  // transform: translateX(100%);

  overflow: hidden;

  .button:global(.hover) &, /* for debugging */
  .button:hover & {
    opacity: 1;
    // transform: translateX(0);
  }
}

.buttonText {
  line-height: var(--main-button--text--line-height);
  width: calc(
    var(--main-button--text--max-width) + var(--main-button--text--padding-left)
  );
  padding-left: var(--main-button--text--padding-left);
}

.buttonTextBig {
  font-size: 26px;
  line-height: 1.15384615;
}

.content {
  display: none;
  // position: absolute;
  // bottom: 70px;
  // right: 20px;
  // background: white;
  // border: 1px solid #ddd;
  // padding: 10px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.content.visible {
  display: block;
}

.background {
  // opacity: 0;
  position: fixed;
  border-radius: 50%;

  --main-button--background--size: var(--main-button--image--width);

  bottom: calc(
    var(--main-button--wrapper--margin) + var(--main-button--wrapper--padding) +
      var(--main-button--image--width) / 2
  );
  right: calc(
    var(--main-button--wrapper--margin) + var(--main-button--wrapper--padding) +
      var(--main-button--image--width) / 2
  );
  width: var(--main-button--background--size);
  aspect-ratio: 1/1;

  transform: translate(50%, 50%);

  //   width: 100%;
  //   height: 100%;
  background-color: var(--main-button--background--background-color);
  transition: all 0.7s ease-in-out;
  //   pointer-events: none;
  pointer-events: none; /* Ensure it does not capture clicks */

  transition-property: opacity, bottom, right, width;
}

.backgroundVisible {
  opacity: 1;
  pointer-events: all;
  --main-button--background--size: 300vmax;
}
