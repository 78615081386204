import React, { Dispatch, SetStateAction, useState } from "react";

import { useTranslation } from "react-i18next";

export const SearchForm = (props: {
  setInputValue: Dispatch<SetStateAction<string>>;
  inputValue: string;
  sendSubmit: (value: string) => void;
  submitDisabled: boolean;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const [valueChanged, setValueChanged] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (props.submitDisabled) {
      return;
    }

    setValueChanged(false);
    props.sendSubmit(props.inputValue);
  };

  const setInputValue = (value: string) => {
    setValueChanged(true);
    props.setInputValue(value);
  };

  return (
    <form onSubmit={handleSubmit} className="question">
      <label htmlFor="question" className="sr-only">
        {t("question.label")}
      </label>
      <input
        placeholder={t("question.placeholder")}
        value={props.inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(event) => {
          // this is needed for multiline support
          if (event.key === "Enter") {
            if (
              !document.activeElement?.getAttribute("aria-activedescendant")
            ) {
              handleSubmit(event);
            }
          }
        }}
      />

      <button
        type="submit"
        tabIndex={-1}
        className="button"
        disabled={props.inputValue.length === 0}
      >
        <span>{t("question.buttonText")}</span>
      </button>
    </form>
  );
};
