import React from "react";
import ReactDOM from "react-dom/client";
import CityGPT from "~/src/components/index.tsx";

class CityGPTElement extends HTMLElement {
  private root: ShadowRoot;

  constructor() {
    super();
    this.root = this.attachShadow({ mode: "open" });
  }

  static get observedAttributes() {
    return ["org"];
  }

  connectedCallback() {
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  render() {
    const org = this.getAttribute("org");
    const lang = this.getAttribute("lang");
    const context = this.getAttribute("context");

    // Clear the shadow root
    while (this.root.firstChild) {
      this.root.removeChild(this.root.firstChild);
    }

    // Attach external stylesheet
    const linkElem = document.createElement("link");
    linkElem.setAttribute("rel", "stylesheet");
    linkElem.setAttribute("href", "city-gpt.css");
    this.root.appendChild(linkElem);

    // Create a container div for React to render into
    const container = document.createElement("div");
    container.classList.add("city-gpt");
    this.root.appendChild(container);

    ReactDOM.createRoot(container).render(
      <CityGPT org={org} lang={lang} context={context} />,
    );
  }
}

customElements.define("city-gpt", CityGPTElement);
