import React from "react";

type MardownSimpleProps = {
  markdown: string;
};

const MardownSimple: React.FC<MardownSimpleProps> = ({ markdown }) => {
  // Split the markdown by double new lines to create paragraphs
  const paragraphs = markdown.split("\n\n");

  // Function to parse links in a string
  const parseLinks = (inputString: string) => {
    const text = inputString.replaceAll("||", "  \n");
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    // Iterate over all matches in the text
    while ((match = linkRegex.exec(text)) !== null) {
      // Push the text before the link
      parts.push(text.slice(lastIndex, match.index));
      // Push the link as an <a> element
      parts.push(
        <a
          key={match.index}
          href={match[2]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {match[1]}
        </a>,
      );
      // Update the last index to the end of the match
      lastIndex = linkRegex.lastIndex;
    }

    // Push the remaining text after the last link
    parts.push(text.slice(lastIndex));
    return parts;
  };

  return (
    <div>
      {paragraphs.map((paragraph, index) => {
        // Split by line breaks (two or more spaces followed by a newline)
        const lines = paragraph.split(/ {2,}\n/);

        return (
          <p key={index}>
            {lines.map((line, lineIndex) => (
              <React.Fragment key={lineIndex}>
                {/* Parse links for each line */}
                {parseLinks(line)}
                {lineIndex < lines.length - 1 && <br />}
              </React.Fragment>
            ))}
          </p>
        );
      })}
    </div>
  );
};

export default MardownSimple;
