import React, { useState, useCallback, useEffect } from "react";

import { SearchForm } from "~/src/components/SearchForm/index";
import { EventSourceMessage, useEventSource } from "./useEventSource";
import { TypingMessageQueue } from "./TypingMessageQueue";
import { useTranslation } from "react-i18next";
import Markdown from "~/src/components/Markdown/index";

import * as classes from "./style.module.scss";

interface ChatProps {
  baseURL: string;
  lang: string;
  children: React.ReactNode;
}

const EVENT_SOURCE_NAME = "default";

export enum DEFAULT_SHORT_NAME {
  MODEL_3_5_4k = "3.5-4k",
  MODEL_3_5_16k = "3.5-16k",
  MODEL_4_TURBO = "4-turbo",
  MODEL_4_0_8k = "4-8k",
  MODEL_4o = "4o",
  MODEL_4o_8k = "4o-8k",
  MODEL_4o_16k = "4o-16k",
}

export interface UsedLinks {
  url: string;
  title?: string;
  type?: string;
  image?: string;
  smallImage?: string;
  date?: string;
  description?: string;
  orgMetadata?: Record<string, any>;
  considered: boolean;
  summary?: string;
}

const DEFAULT_MODEL = DEFAULT_SHORT_NAME.MODEL_4o;
const DEFAULT_OTHER_MODEL =
  DEFAULT_MODEL === DEFAULT_SHORT_NAME.MODEL_4o
    ? DEFAULT_SHORT_NAME.MODEL_4o_8k
    : DEFAULT_SHORT_NAME.MODEL_3_5_16k;

// https://lozaerncitygpt-api.gpt.liip.ch/lozaerncitygpt/assistant/sse?query=Wer%20ist%20Markus%20Hess%3F&key=X9hL4Gp5W2D7eRtF&r=362&uID=d5614158-809c-444b-ae25-33b51d6fbc91&lang=de

export const Chat: React.FC<ChatProps> = ({
  children,
  baseURL,
  lang = "de",
}) => {
  return (
    <div className={classes.root}>
      <h1>Yay!</h1>
    </div>
  );
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const [linksResult, setLinksResult] = useState<string>("");
  const [queryResult, setQueryResult] = useState<string>("");
  const [maybeBadRating, setMaybeBadRating] = useState<boolean>(false);

  const [requestId, setRequestId] = useState<string | null>(null);
  const [erzFunction, setErzFunction] = useState<{
    query: string;
    function: string;
  } | null>(null);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [disabledTimeout, setDisabledTimeout] = useState<number | undefined>(
    undefined,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [sseUri, setSseUri] = useState("");
  const [usedLinksResult, setUsedLinksResult] = useState<UsedLinks[]>([]);

  const { close: eventSourceClose, start: eventSourceStart } = useEventSource();

  const setNewEventSource = useCallback(
    (queryValue: string, model: string, ft?: string) => {
      if (queryValue && baseURL) {
        // the random part is just that we create a new event source when we submit...
        // maybe there are better solutions for this.
        const source = `${baseURL}?query=${encodeURIComponent(
          queryValue,
        )}&key=X9hL4Gp5W2D7eRtF&r=${Math.floor((new Date().getTime() / 1000) % 600)}&lang=${lang}${model !== DEFAULT_MODEL ? `&modelId=${model}` : ""}`;

        setSseUri(source);
      }
    },
    [baseURL, lang],
  );

  const sendSubmit = (inputValue: string, model = DEFAULT_MODEL) => {
    if (inputValue.trim() === "") return;

    const fetchData = async () => {
      // setIsLoading(true);
      // setErzFunction(null);
      // setOtherModel(false);
      // setUsedLinksResult([]);
      setNewEventSource(inputValue, model);
      // setLinksResult("");
      // setMaybeBadRating(false);
      // setQueryResult(t("answers.oneMoment"));
    };
    setSubmitDisabled(true);
    setQueryValue(inputValue);
    if (disabledTimeout) {
      clearTimeout(disabledTimeout);
    }
    setDisabledTimeout(
      window.setTimeout(() => {
        setSubmitDisabled(false);
      }, 5000),
    );

    fetchData();
  };

  const parseMessageFromEventSource = useCallback(
    (parsedData: Record<string, any>, queue: TypingMessageQueue) => {
      try {
        if (parsedData.links) {
          setLinksResult((linksResult) => {
            return linksResult.concat(parsedData.links);
          });
        }
        if (parsedData.usedLinks) {
          setUsedLinksResult((linksResult) => {
            return linksResult.concat(parsedData.usedLinks);
          });
        }
        if (parsedData.maybeBadRating) {
          setMaybeBadRating(true);
        }
        if (parsedData.response) {
          const answerEnd = () => {
            setSubmitDisabled(false);
            setIsLoading(false);
          };
          if (parsedData.response === "__THIS_IS_THE_ANSWER_END__") {
            answerEnd();
            return;
          }

          if (parsedData.response === "__THIS_IS_THE_END__") {
            eventSourceClose(EVENT_SOURCE_NAME);
            queue.enqueue(parsedData.response);
            queue.setThisIsTheEnd();
            setQueryValue("");
            answerEnd();
            return;
          }
          if (parsedData.response === "__CLR__") {
            setSubmitDisabled(false);
            queue.enqueue(parsedData.response);
            return;
          }

          queue.enqueue(parsedData.response);
          /*setQueryResult(queryResult => {
              return queryResult.concat(parsedData.response)
            })*/
        }
        if (parsedData.erz) {
          setErzFunction({
            query: queryValue,
            function: JSON.stringify(parsedData.erz),
          });
        }
        if (parsedData.timetable) {
          setErzFunction({
            query: queryValue,
            function: JSON.stringify(parsedData.timetable),
          });
        }
        if (parsedData.id) {
          setRequestId(parsedData.id);
        }
        if (
          parsedData.model &&
          parsedData.model.model === DEFAULT_OTHER_MODEL
        ) {
          setSubmitDisabled(false);
          setQueryResult(`Wir versuchen's mit längerem Kontext...`);
          if (parsedData.model.query) {
            setQueryValue(parsedData.model.query);
            setNewEventSource(
              parsedData.model.query,
              parsedData.model.model,
              parsedData.model.ft,
            );
            setInputValue(parsedData.model.query);
          } else {
            setNewEventSource(
              queryValue,
              parsedData.model.model,
              parsedData.model.ft,
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [eventSourceClose, queryValue, setNewEventSource],
  );

  useEffect(() => {
    let linksReceived = false;
    try {
      if (!sseUri || !queryValue) {
        return;
      }
      setIsLoading(true);
      setLinksResult("");
      setUsedLinksResult([]);
      setMaybeBadRating(false);
      setQueryResult(t("answers.oneMoment"));

      const queue = new TypingMessageQueue(setQueryResult);
      const onerror = (e: Event) => {
        // for some strange reason, we get an error event before the server sends the __THIS_IS_THE_END__ event
        // in some cases. We don't need to show that error, after we received some links... The main result is here anyway
        // For example: Ask for "Wer ist Inka?" on WintiGPT....
        if (!linksReceived) {
          setQueryResult((queryResult) =>
            queryResult.concat(`

  ${t("answers.serverConnectionError")}`),
          );
        }
        console.log("SSE Error", e);
        eventSourceClose(EVENT_SOURCE_NAME);
        setQueryValue("");
        setSubmitDisabled(false);
        setIsLoading(false);
        // setOtherModel(false);
      };
      const onmessage = (event: EventSourceMessage) => {
        const parsedData = JSON.parse(event.data);

        if (parsedData.links) {
          linksReceived = true;
        }
        parseMessageFromEventSource(parsedData, queue);
      };
      eventSourceStart(EVENT_SOURCE_NAME, sseUri, onmessage, onerror);

      return () => {
        eventSourceClose(EVENT_SOURCE_NAME);
      };
    } catch (error) {
      console.log(error);
    }
  }, [
    eventSourceClose,
    eventSourceStart,
    parseMessageFromEventSource,
    queryValue,
    sseUri,
    t,
  ]);

  return (
    <div className={classes.root}>
      {children}
      <p>Language: {lang}</p>
      <p>Chat</p>

      <Markdown
        markdown={
          queryResult +
          (isLoading
            ? " ![](https://chregus.rokka.io/dynamic/crop-width-200-height-25--resize-width-20/o-af-1/b9a927/circles-menu-1.gif)"
            : "")
        }
        usedLinks={usedLinksResult}
      ></Markdown>

      <SearchForm
        setInputValue={setInputValue}
        inputValue={inputValue}
        sendSubmit={sendSubmit}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
      />
    </div>
  );
};
