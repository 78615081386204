import React, { useEffect, useState } from "react";
import { MainButton } from "~/src/components/MainButton/index.tsx";
import { Chat } from "~/src/components/Chat/index.tsx";
import * as classes from "./style.module.scss";
import classNames from "classnames";
import { useFetch } from "~/src/hooks/useFetch"; // Import your useFetch hook
import i18n from "./i18n"; // Import i18n instance

import "./_root/style.scss";

interface CityGPTProps {
  org: string | null;
  lang: string | null;
  context: string | null;
}

const HOST_POSTFIX = "-api.gpt.liip.ch";

export const StyleReset: React.FC<CityGPTProps> = ({ org, lang, context }) => {
  const {
    data: prefs,
    error,
    isLoading,
  } = useFetch({
    // url: `https://${org}${HOST_POSTFIX}/${org}/info?source=${lang}:${context}`,
    url: `https://${org}${HOST_POSTFIX}/${org}/info?source=${lang}:preferences`,
  });

  console.log("useFetch", { isLoading, error, prefs });

  const [startupFinished, setStartupFinished] = useState(false);

  useEffect(() => {
    console.log("effect");
    // Apply the fetched translations to i18next
    if (prefs && lang) {
      const translations = prefs?.autocomplete
        .filter((item) => item.value.startsWith("translation"))
        .reduce((acc, item) => {
          const [key, value] = item.value.split(":");
          // the key is a dot separated string, so we need to split it to put it in the correct object
          const keys = key.split(".");
          keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value;
            } else {
              acc[key] = acc[key] || {};
            }
            return acc[key];
          }, acc);

          return acc;
        }, {});
      // i18n.addResources(lang, "translation", translations);
      console.log("translations", translations.translation);

      i18n.addResourceBundle(lang, "translation", translations.translation);
      setStartupFinished(true);
    }
  }, [prefs, lang]);

  if (!startupFinished) {
    return;
  }

  if (isLoading) {
    return;

    // return (
    //   <div className={classNames(classes.overlay, classes.hidden)}>
    //     <h1>Loading...</h1>
    //   </div>
    // );
  }

  if (error) {
    return;
    // sendToSentry(error);
    // return (
    //   <div className={classNames(classes.overlay, classes.error)}>
    //     <h1>Error fetching translations!</h1>
    //     <p>There was an error fetching the translations. Please try again.</p>
    //   </div>
    // );
  }

  if (!prefs) {
    return;
    // return (
    //   <div className={classNames(classes.overlay, classes.hidden)}>
    //     <h1>No Translations Available</h1>
    //   </div>
    // );
  }

  console.log("render", org, lang, isLoading);

  return (
    <div className={classNames([org, classes.root])}>
      <CityGPT org={org} lang={lang} context={context} />
    </div>
  );
};

const CityGPT: React.FC<CityGPTProps> = ({ org, lang, context }) => {
  if (!org || !lang || !context) {
    return (
      <div className={classes.overlay}>
        <h1>Setup Error!</h1>
        <p>Please set lang, org, and context in component</p>
      </div>
    );
  }

  return (
    <MainButton>
      <Chat
        baseURL={`https://${org}${HOST_POSTFIX}/${org}/assistant/sse`}
        lang={lang}
      >
        {/* <p>Organization: {org}</p> */}
      </Chat>
    </MainButton>
  );
};

export default StyleReset;
